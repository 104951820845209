import React, { useContext } from 'react';
import { BlockImg, Block4Col, BlockProjTitle } from "../../component/Block";
import { Container } from "../../component/Core";
import ca1 from "../../Assets/CanooDS/21.01.jpg";
import ca2 from "../../Assets/CanooDS/21.02.jpg";
import ca3 from "../../Assets/CanooDS/21.03.jpg";
import ca4 from "../../Assets/CanooDS/21.04.jpg";
import ca5 from "../../Assets/CanooDS/21.05.jpg";
import ca6 from "../../Assets/CanooDS/21.06.jpg";
import ca7 from "../../Assets/CanooDS/21.07.jpg";
import ca8 from "../../Assets/CanooDS/21.08.jpg";
import ca9 from "../../Assets/CanooDS/21.09.jpg";
import ca10 from "../../Assets/CanooDS/21.10.jpg";
import ca11 from "../../Assets/CanooDS/21.11.jpg";
import ca12 from "../../Assets/CanooDS/21.12.jpg";
import ca13 from "../../Assets/CanooDS/21.13.jpg";
import { PasswordContext } from '../../component/PasswordContext';
import PasswordPopup from '../../component/PasswordPopup';
import { OtherProj } from "../../component/OtherProj";

const CanooDS = () => {
  const { isPasswordProtected } = useContext(PasswordContext);

  const ImgSet = [
    { img: ca1 },
    { img: ca2 },
    { img: ca3 },
    { img: ca4 },
    { img: ca5 },
    { img: ca6 },
    { img: ca7 },
    { img: ca8 },
    { img: ca9 },
    { img: ca10 },
    { img: ca11 },
    { img: ca12 },
    { img: ca13 }
  ]


  return (

    <Container width={[1]} flexDirection="column" flexWrap="wrap" alignItems="center">

        <>
          <BlockProjTitle
            Project={"Canoo Design Process"}
            Role={"Design Lead"}
            Deli1={"Art Direction, Design System"}
            Deli2={""}
            Deli3={""}
            En={"To enhance Canoo's design and development processes, we aim to establish a comprehensive design system rooted in functionalism. By addressing the current challenges of misalignment, slow turnaround times, and inconsistent aesthetics, we can streamline workflows, improve code quality, and ensure adherence to our company's minimalist design philosophy. Our solution involves creating a centralized repository of reusable UI components, developing a detailed style guide, implementing a version control system, and providing ongoing training and support. This approach will foster collaboration between design and development teams, accelerate project timelines, and deliver high-quality products that align with our brand identity. "}
            Jp={"为了提升 Canoo 的设计和开发流程，我们旨在建立一个基于功能主义的综合设计系统。通过解决当前存在的错位、缓慢的周转时间和不一致的美学问题，我们可以简化工作流程，提高代码质量，并确保遵守公司简约的设计理念。我们的解决方案包括创建一个集中的可重用 UI 组件库、开发详细的样式指南、实施版本控制系统以及提供持续的培训和支持。这种方法将促进设计和开发团队之间的协作，加快项目时间表，并交付与我们品牌形象相符的高质量产品。"}
          />
          {ImgSet.map((imageObj, index) => (
         <BlockImg key={index} image={imageObj.img} />
      ))}
                <Block4Col pb={[4]} size={[1]} gap={[1]} line={"1px #d5d5d5 solid"}
        col3a={"Team"}
        col4a={"Kaylee - Designer"}
        col4b={" "}
        col4c={" "}
      />
      <Block4Col pb={[4]} size={[1]} gap={[1]} line={"1px #d5d5d5 solid"}
        col3a={"Director"}
        col4a={"Nathan Smith"}
      />
       <OtherProj/>
        </>

    </Container>
  );
};

export default CanooDS;



