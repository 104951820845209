import React from 'react';
import "./style/app.css";

import Home from "./pages/Home";
import Work from "./pages/Work";
import About from "./pages/About";
import Nike from "./pages/work/Nike";
import Shopify from "./pages/work/Shopify";
import CanooB from "./pages/work/CanooB";
import CanooW from "./pages/work/CanooW";
import Process from "./pages/work/Process";
import Figs from "./pages/work/Figs";
import Logo from "./pages/work/Logo";
import Inno from "./pages/work/inno";
import Hyundai from "./pages/work/Hyundai";
import Treedom from "./pages/work/treedom";
import CanooDS from "./pages/work/CanooDS";
import CanooHMI from "./pages/work/CanooHMI";
import License from "./pages/License";
import Privacy from "./pages/Privacy";

import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import { Container } from "./component/Core";

import PageWrapper from "./pages/PageWrapper";
import { BlockFooter, BlockMenu } from "./component/Block";

import PasswordProtectedRoute from './component/PasswordProtectedRoute';


function App() {





  return (
    <Router>
      <Container width={[1, 1, 1, 1]} display="flex" flexDirection="column" justifyContent="center" alignItems="center" >
        <Routes>
          <Route path="/" element={<PageWrapper component={<Home />} />} />
          <Route path="/work/treedom" element={<PageWrapper component={<Treedom />} />} />
          <Route path="/work/figs" element={<PageWrapper component={<Figs />} />} />
          <Route path="/work/hyundai" element={<PageWrapper component={<Hyundai />} />} />
          <Route path="/work/shopify" element={<PageWrapper component={<Shopify />} />} />
          <Route path="/work/nike" element={<PageWrapper component={<Nike />} />} />
          <Route path="/work/Inno" element={<PageWrapper component={<Inno />} />} />
          <Route path="/logo" element={<PageWrapper component={<Logo />} />} />
          <Route path="/work/CanooB" element={<PageWrapper component={<CanooB />} />} />
          <Route path="/work/CanooW" element={<PageWrapper component={<CanooW />} />} />
          <Route path="/Process" element={<PageWrapper component={<Process />} />} />
          <Route path="/work/CanooDS" element={<PageWrapper component={<CanooDS />} />} />
          <Route path="/work/CanooHMI" element={<PageWrapper component={<CanooHMI />} />} />
          <Route path="/license" element={<PageWrapper component={<License />} />} />
          <Route path="/Privacy" element={<PageWrapper component={<Privacy />} />} />
          <Route path="/work" element={<PageWrapper component={<Work />} />} />
          <Route path="/About" element={<PageWrapper component={<About />} />} />
        </Routes>
        <BlockMenu />
        <BlockFooter />
      </Container>
    </Router>
  );
}

export default App;
