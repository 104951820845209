import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { layout, flexbox, position, width, zIndex } from "styled-system";
import { base } from "../theme";
import { BImage, Thumbnail, H1, Text, Textcn, Button, Container } from "./Core";

export const Block = styled.div`
background-color: ${(props) => props.theme.colors.gre10};
display: flex;
flex-direction: row;
width: 100vw;
flex-wrap: wrap;
${zIndex};
`

export const CenterWrap = styled.div`
${layout};
${width};
${position};
${flexbox};
`

export const BlockImg = (props) => {
  return (
    <ThemeProvider theme={base}>
      <BImage src={props.image} width={props.width} height={props.height} pb={[0]}/>
    </ThemeProvider>
  )
};

export const BlockMenu = (props) => {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate("/");
  };
  const navigateAbout = () => {
    navigate("/about");
  };
  // const navigateIndex = () => {
  //   navigate("/Index");
  // };
  // const navigateShop = () => {
  //   navigate("/Shop");
  // };

  return (
    <ThemeProvider theme={base}>
      <Container width="100%" >
      <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateHome}> Tsz </Button>
      <Button fontFamily={[0]} width="25%" fontSize={[1]} padding={[3]} variant="default" flexGrow="1" textAlign="left" onClick={navigateAbout}> Info </Button>
      </Container>
    </ThemeProvider>
  )
};

export const BlockFooter = (props) => {
  const navigate = useNavigate();

  const navigateLicense = () => {
    navigate("/License");
  };
  const navigatePrivacy = () => {
    navigate("/Privacy");
  };
  const navigateShipping = () => {
    navigate("/Shipping");
  };

  return (
    <ThemeProvider theme={base}>
      <Container className="block-footer" justifyContent="start" flexDirection={["row"]} flexWrap="wrap" zIndex="1" width={[1]} pt="3" pb="4" bg="#000" color="grey">
    
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://www.linkedin.com/in/tszhoip", "_blank")}> Linkedin </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://www.instagram.com/z.type.nn/", "_blank")}> Instagram </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://savee.it/tszhoip/", "_blank")}> Savee.it </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={() => window.open("https://www.cosmos.so/tszhoip", "_blank")}> Cosmos.so </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigatePrivacy}> Privacy </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigateLicense}> License </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footer" textAlign="left" onClick={navigateLicense}> Shipping </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footerDisable" textAlign="left" > 626 390 2555 </Button>
        <Button width={[1 / 2, 1 / 2, 1 / 4, 1 / 4]} padding={[3]} fontFamily={[0]} fontSize={[1]} variant="footerDisable" textAlign="left" > tszhoip@gmail.com </Button>
      </Container>
    </ThemeProvider>
  )
};


export const BlockThumb = (props) => {
  const { width, ...restProps } = props;
  return (
    <ThemeProvider theme={base}>
      <Thumbnail {...restProps} width={width} height={["auto"]} display="flex" flexDirection="column" to={props.to}>
        {/* <ImgBlock2 height={props.height} src={props.img} side={props.side} /> */}
        <BlockImg height={props.height} image={props.img} />
        <Text fontFamily={[0]} fontSize={[1]} p={[3]} >{props.title}</Text>
      </Thumbnail>
    </ThemeProvider>
  )
}



export const BlockThumbFull = (props) => {
  const { width, ...restProps } = props;
  return (
    <ThemeProvider theme={base}>
      <Thumbnail {...restProps} width={width} height={["350px", "400px", "450px", "500px"]} to={props.to}>
        <Text fontFamily={[0]} fontSize={[1]} p={[3]}>{props.title}</Text>
      </Thumbnail>
    </ThemeProvider>
  )
}






export const BlockHeader = ({ title, description, buttonText, handleBuyClick, zIndex }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%", "100%", "100%", "100%"]} position="fixed" top="0" flexDirection={["row"]} zIndex="999" height={["64px", "64px", "64px", "64px"]}>
        <Container flexGrow={["6", "6", "6", "6"]} p="3" flexDirection="column" justifyContent="center">
          <H1 fontFamily={[0]} fontSize={[1]} fontWeight="600">{title}</H1>
          <H1 fontFamily={[0]} fontSize={[1]} fontWeight="400">{description}</H1>
        </Container>
        <Container p="2">
          <Button fontFamily={[0]} fontSize={[1]} p="3" flexShrink="1" fontWeight="600" variant="primaryR" fle onClick={handleBuyClick} >{buttonText}</Button>
        </Container>
      </Container>
    </ThemeProvider>
  )
}



export const Block2Col = ({
  col1a, col1b, col1c, col1d,
  col2a, col2b, col2c, col2d,
  pb, size, gap, line }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} pb={[0, 1, { pb }, { pb }]} flexDirection={["column", "row", "row", "row"]} borderBottom={line}>
        <Container flexGrow="4" width={[1, 1, 1 / 2, 1 / 2]} p={[3]} flexDirection={["column", "column", "column", "column"]} justifyContent="left">
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col1a}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col1b}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col1c}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col1d}</Text>
        </Container>
        <Container flexGrow="4" width={[1, 1, 1 / 2, 1 / 2]} p={[3]} flexDirection="column" justifyContent="left">
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col2a}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col2b}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col2c}</Text>
          <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} variant="" pb={gap} fontSize={{ size }}>{col2d}</Text>
        </Container>
      </Container>
    </ThemeProvider>
  )
}


export const Block4Col = ({
  col1a, col1b, col1c,
  col2a, col2b, col2c, col2d,
  col3a, col3b, col3c, col3d,
  col4a, col4b, col4c, col4d,
  pb, size, gap, line }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%"]} pb={[pb]} borderBottom={line} flexWrap="Wrap">
        <Container flexGrow="1" width={[1 / 2, 1 / 4, 1 / 4, 1 / 4]}>
          <Container p={[3]} flexDirection="column" justifyContent="left">
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col1a}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col1b}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col1c}</Text>
          </Container>
        </Container>
        <Container flexGrow="1" width={[1 / 2, 1 / 4, 1 / 4, 1 / 4]}>
          <Container p={[3]} flexDirection="column" justifyContent="left">
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col2a}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col2b}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col2c}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col2d}</Text>
          </Container>
        </Container>
        <Container flexGrow="1" width={[1 / 2, 1 / 4, 1 / 4, 1 / 4]}>
          <Container p={[3]} flexDirection="column" justifyContent="left">
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col3a}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col3b}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col3c}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col3d}</Text>
          </Container>
        </Container>
        <Container flexGrow="1" width={[1 / 2, 1 / 4, 1 / 4, 1 / 4]}>
          <Container p={[3]} flexDirection="column" justifyContent="left">
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col4a}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col4b}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col4c}</Text>
            <Text fontFamily={[0]} color={["blk50"]} variant="" pb={gap} fontSize={size}>{col4d}</Text>
          </Container>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export const BlockList2 = ({ title, description }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={["100%"]} borderBottom="1px lightgrey solid">
        <Container width={["50%"]} flexDirection="row" justifyContent="left">
          <Text fontFamily={[0]} color={["blk70"]} variant="" fontSize={[1]} p={[3]}>{title}</Text>
        </Container>
        <Container width={["25%"]} flexDirection="column" justifyContent="left" pt={[3]} pb={[3]}>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
        </Container>
        <Container width={["25%"]} flexDirection="column" justifyContent="left" pt={[3]} pb={[3]}>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
          <Text fontFamily={[0]} color={["blk50"]} variant="" fontSize={[1]} pl={[3]}>{description}</Text>
        </Container>
      </Container>
    </ThemeProvider>
  )
}

export const Block1Col = ({ Headline, Subhead, Height, Width }) => {
  return (
    <ThemeProvider theme={base}>
      <Container height={{ Height }} width={{ Width }} pt={[2]} pb={[6]} flexDirection="column" justifyContent="left">
        <H1 fontFamily={[0]} width={["90%"]} color={["blk50"]} fontSize={[4]} p={[3]}>{Headline}</H1>
        <Text fontFamily={[0]} width={["90%"]} color={["blk50"]} fontSize={[2]} p={[3]}>{Subhead}</Text>
      </Container>
    </ThemeProvider>
  )
}

export const BlockProjTitle = ({ Project, Role, Deli1, Deli2, Deli3, En, Jp }) => {
  return (
    <ThemeProvider theme={base}>
      <Container width={[1]} pt={[2]} pb={[3, 4, 5, 6]} flexDirection={["column", "column", "row", "row"]} justifyContent="left">
        <Container width={[1, 1, 1 / 2, 1 / 2]} flexDirection={"row"}>
          <Container width={"100%"} flexDirection={["row", "row", "column", "column"]} pt={[3]} flexWrap={"wrap"}>
            <Container width={1 / 2} flexDirection={["column"]}>
              <Text fontFamily={[0]} color={["blk70"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1]} pl={[3]}>Project</Text>
              <Text fontFamily={[0]} color={["blk50"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1, 1, 1, 2]} pl={[3]} pb={[4]}>{Project}</Text>
            </Container>
            <Container width={1 / 2} flexDirection={["column"]}>
              <Text fontFamily={[0]} color={["blk70"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1]} pl={[3]}>Role</Text>
              <Text fontFamily={[0]} color={["blk50"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1, 1, 1, 2]} pl={[3]} pb={[4]}>{Role}</Text>
            </Container>
            <Container width={1 / 2} flexDirection={["column"]}>
              <Text fontFamily={[0]} color={["blk70"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1]} pl={[3]}>Deliverable</Text>
              <Text fontFamily={[0]} color={["blk50"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1, 1, 1, 2]} pl={[3]}>{Deli1}</Text>
              <Text fontFamily={[0]} color={["blk50"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1, 1, 1, 2]} pl={[3]}>{Deli2}</Text>
              <Text fontFamily={[0]} color={["blk50"]} width={[1, 1, 1 / 2, 1 / 2]} fontSize={[1, 1, 1, 2]} pl={[3]}>{Deli3}</Text>
            </Container>
          </Container>
        </Container>
        <Container width={[1, 1, 1 / 2, 1 / 2]} flexDirection={["column", "row", "row", "row"]}>
          <Container width={[1, 3 / 4, 7 / 8, 3 / 4]} flexDirection={"column"} pt={[3]}>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>EN</Text>
            <Text fontFamily={[0]} color={["blk50"]} fontSize={[1, 1, 1, 2]} pl={[3]} pb={[4]}>{En}</Text>
            <Text fontFamily={[0]} color={["blk70"]} fontSize={[1]} pl={[3]}>CN</Text>
            <Textcn fontFamily={[0]} color={["blk50"]} fontSize={["11px", "11px", "11px", "14px"]} m={[0]} pl={[3]} pr={[3]} pb={[4, 2, 1, 1]} >{Jp}</Textcn>
          </Container>
        </Container>
      </Container>
    </ThemeProvider>
  )
}


export const BlockThumbS = ({ imag, title, description, width, to, height,bgColor, bgColorHover, tColor, tColorHover }) => {
  return (
    <>
      
        <Container width={[1/2,1/2,1/4,1/4]} height={height} display="block"  >
          <Thumbnail 
            width="100%" display="flex" 
            flexDirection="row" to={to} 
            bgColor={bgColor} bgColorHover={bgColorHover}
            tColor={tColor} tColorHover={tColorHover}
            >
              <Container width={[1 / 2]} display="flex" flexDirection="column" p={3}>
            <BlockImg image={imag}  />
            </Container>
            <Container width={[1 / 2]} display="flex" flexDirection="column">
              <Text fontFamily={[0]} pt={[3]}  variant="" fontSize={[1]} >{title}</Text>
              <Text fontFamily={[0]}  variant="" fontSize={[1]} >{description}</Text>
            </Container>
          </Thumbnail>
        </Container>
  
    </>
  )
}